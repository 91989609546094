import $ from 'jquery';
import Swiper from 'swiper';

class Project {
  constructor() {
    this.initSwiper('.swiper-container');
  }

  /**
   * Initialize Swiper
   * @param  {string} containing element for the swiper object
   */
  initSwiper(el) {
    let swiper = new Swiper (el, {
      direction: 'horizontal',
      loop: true,
      grabCursor: true,
      initialSlide: 0,
      hashnav: true,
      keyboardControl: true,
      pagination: '.swiper-pagination',
      paginationType: 'fraction',
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    });
  }
}

export default Project;
