import $ from 'jquery';
import SlideToggle from './slideToggle';

class Nav {
  constructor() {
    this.$mobileButton = $('.mobile-button');
    this.$body = $('body');
    new SlideToggle('.header__nav-list');
    this.initHandlers();
  }

  initHandlers() {
    this.$mobileButton.on('click',  this.toggleNav.bind(this));
  }

  toggleNav() {
    this.$body.toggleClass('nav-is-open');
    let toggleEl = this.$body;
    let list = $('.header__nav-list');

    SlideToggle.toggleClass(toggleEl, 'nav-is-open', list);
  }
}

export default Nav;
