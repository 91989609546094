import $ from 'jquery';

class SlideToggle {
  constructor(el) {
    this.setToggleHeight(el);
  }

  /**
   * Store the height of the outer container to toggle in a data attribute
   * @param {string} el element to pass in
   */
  setToggleHeight(el) {
    let toggleItem = document.querySelectorAll(el);

    toggleItem.forEach(function (item) {
      let itemHeight = item.scrollHeight;
      item.setAttribute('data-height', itemHeight);
    });
  }

  /**
   * [toggleClass description]
   * @param  {string} classname body toggle class
   * @param  {string} el        element to toggle
   */
  static toggleClass(toggleEl, toggleClass, el) {

    if(toggleEl.hasClass(toggleClass)) {
      el.css('maxHeight', el.data('height'));
    } else {
      el.removeAttr('style');
    }
  }
}

export default SlideToggle;
