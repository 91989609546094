import $ from 'jquery';
import Isotope from 'isotope-layout';
import SlideToggle from './slideToggle';

class Work {
  constructor() {
    this.$window = $(window);
    this.$workFilter = $('.work-filter');
    this.$workFilterH3 = $('.work-filter__h3');
    this.$workList = $('.work-filter__list');
    this.$workFilterLink = $('.js--iso-link');
    new SlideToggle('.work-filter__list');
    this.isoGrid = '';
    this.filters = {};
    this.initIsotope();
    this.isIsotopeInit = false;
    this.initHandlers();
    this.onHashChange();
  }

  initHandlers() {
    this.$workFilterH3.on('click', this.toggleFilter.bind(this));
    this.$workFilterLink.on('click', this.isotopeFilter.bind(this));
    this.$window.on('hashchange', this.onHashChange.bind(this));
  }

  /**
   * Logic for toggling the display of the filter dropdown
   */
  toggleFilter(e) {
    e.preventDefault();

    let el = $(e.currentTarget).parent();
    let list = el.find('.work-filter__list');
    el.toggleClass('filter-is-open');

    SlideToggle.toggleClass(el, 'filter-is-open', list);
  }

  /**
   * Initialize Isotope Object
   */
  initIsotope() {
    this.isoGrid = new Isotope( '.masonry-grid', {
      itemSelector: '.masonry-grid__item',
      percentPosition: true,
      stagger: 20,
      transitionDuration: '0.2s',
      hiddenStyle: {
        opacity: 0
      },
      visibleStyle: {
        opacity: 1
      },
      masonry: {
        columnWidth: '.masonry-grid__sizer'
      }
    });
  }

  /**
   * Filter logic for multiple filters
   * See http://isotope.metafizzy.co/v1/demos/infinite-scroll.html if we plan to use this
   * with infinite scroll and pagination
   */
  isotopeFilter(e) {
    e.preventDefault();

    let $this = $(e.currentTarget);
    let selector = $(e.currentTarget).data('filter-value');
    let currentLink = $(e.currentTarget).parent();
    let parent = $(e.currentTarget).parents('.work-filter__list');

    parent.children().not(currentLink).removeClass('work-filter__list-item--active');
    currentLink.addClass('work-filter__list-item--active');

    if(currentLink.children().hasClass('work-filter__view-all-link')) {
      this.$workFilterLink.parent().removeClass('work-filter__list-item--active');
    }

    let filterGroup = parent.data('filter-group');
    this.filters[ filterGroup ] = $this.data('filter-value');
    let filterValue = this.concatValues(this.filters);

    if($this.hasClass('work-filter__view-all-link')) {
      this.filters = {};
      filterValue = '';
      $('.work-filter__grid-item').removeClass('filter-is-open');
      $('.work-filter__list').css('max-height', '0');

    }

    this.setHash(filterValue);

    this.isoGrid.arrange({ filter: filterValue });
    return false;
  }
  /**
   * Set URL Hash Query String
   * @param {string} a concatenated string containing the filter value
   */
  setHash(value) {
    location.hash = 'filter=' + encodeURIComponent(value);
  }

  /**
   * Get the hash string from the url bar
   * @return {string} retrieve readable string from the url
   */
  getHashFilter() {
    let hash = location.hash;
    let matches = location.hash.match(/filter=([^&]+)/i);
    let hashFilter = matches && matches[1];

    return hashFilter && decodeURIComponent(hashFilter);
  }

  /**
   * On hash change event
   */
  onHashChange() {

    let hashFilter = this.getHashFilter();
    let urlArray = hashFilter.split('.');
    let $currentItem;

    urlArray.forEach((item) => {
      if(item !== '') {
        $currentItem = $('[data-filter-value="' + '.' + item + '"]');

        let el = $currentItem.parents('.work-filter__grid-item');
        let list = $currentItem.parents('.work-filter__list');

        $currentItem.parent().addClass('work-filter__list-item--active');
        $currentItem.parents('.work-filter__grid-item').addClass('filter-is-open');

        SlideToggle.toggleClass(el, 'filter-is-open', list);
      }
    });

    if (!hashFilter && this.isIsotopeInit) {
      return;
    }

    this.isIsotopeInit = true;
    this.isoGrid.arrange({ filter: hashFilter });
  }

  /**
   * Concat values into single object
   * @param  {obj} object with key value
   * @return {string} flattened filter list
   */
  concatValues (obj) {
    var value = '';
    for ( var prop in obj ) {
      value += obj[ prop ];
    }
    return value;
  }
}

export default Work;
