import Nav from './modules/nav';
import Project from './modules/project';
import Work from './modules/work';
import $ from 'jquery';

window.onload = () => {

  new Nav();

  if($('.work').length) {
    new Work();
  }

  if($('.project').length) {
    new Project();
  }
};
